<template>
  <UiIcon
    v-if="isLoggedIn"
    name="user"
    @click.prevent="toggleAppAccount()"
  />
</template>

<script setup lang="ts">
import UiIcon from '@ui/components/UiIcon/UiIcon.vue'
import { useDrawer } from '@base/components/AppDrawer/useDrawer'
import { useCustomer } from '../../composables/useCustomer'

const { isLoggedIn } = useCustomer()
const { toggleAppAccount } = useDrawer()
</script>
